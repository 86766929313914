<template>
  <div class="rma-addmessage position-relative">
      <absolute-loader :showLoader="callLoader"></absolute-loader>
      <div class="rma-addmessage_msg form-group">
        <h3 v-t="'informationPlus'" class="mb-3"></h3>
        <base-textarea
          name="rmaMessage"
          v-model="message"
          :placeholder="$t('rma.add_message')"
        />
      </div>
      <!-- attach file -->
      <div>
        <h3 v-t="'rma.add_files'" class="mb-3"></h3>
        <div class="rma-addmessage_files mb-4_1 form-group">
          <b-form-file
            id="form-file-img"
            :placeholder="$t('rma.add_files')"
            drop-placeholder="Drop file here..."
            class="aqz-input-file"
            @change="processFile($event)">
            </b-form-file>
            <ul class="files-list-text list-group">
              <li v-for="(file, index) in _files" :key="file.lastModified" class="filename-list list-group-item">
                <button @click="removeFile(index)" class="btn btn-none font-weight-bold"><i class="ff-icon-04-close"></i></button>
                {{file.name}}
              </li>
            </ul>
        </div>
        <b-button type="button" variant="primary" @click="sendMessage()" class="form-group" v-if="!creationForm">{{$t('Submit')}}</b-button>
      </div>
  </div>
</template>

<script>
import Rma from '@/services/Rma'
import $ from 'jquery'
import BaseTextarea from '@/components/Form/BaseTextarea'
import AbsoluteLoader from '@/theme/components/AbsoluteLoader/AbsoluteLoader'
import { toast } from '@/modules/notifications'
import Logger from '@/services/Logger'

export default {
  props: {
    rmaId: {
      type: String,
      default: ''
    },
    reply: {
      type: String,
      default: ''
    },
    files: {
      type: Array,
      default: () => []
    },
    creationForm: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    callLoader: false
  }),
  computed: {
    message: {
      get () {
        return this.reply
      },
      set (newValue) {
        this.$emit('update:reply', newValue)
      }
    },
    _files: {
      get () {
        return this.files
      },
      set (newValue) {
        this.$emit('update:files', newValue)
      }
    }
  },
  methods: {
    sendMessage (rmaId, skipFile) {
      const _this = this
      if (this.message || this._files.length) {
        if (skipFile && this._files.length === 0) {
          return Promise.resolve(true)
        }
        if (!_this.creationForm) {
          this.callLoader = true
        }
        return Rma.attachment({
          token: this.$store.state.user.token,
          storeViewCode: this.$store.state.ui.storeViewCode,
          rmaId: rmaId || this.rmaId,
          message: this.message,
          files: this._files
        })
          .then(res => {
            if (res.data && res.data.success) {
              _this.$emit('saved')
              _this.emptyAll()
              return true
            } else {
              Logger.error(res)
              !_this.creationForm && toast.error(_this.$t('GenericError'))
            }
            return false
          })
          .catch(error => {
            Logger.error(error)
            !_this.creationForm && toast.error(_this.$t('GenericError'))
            return false
          })
          .finally(() => {
            _this.callLoader = false
          })
      }
      return Promise.resolve(true)
    },
    emptyAll () {
      $('#form-file-img + label').empty()
      this._files = []
      this.message = ''
    },
    processFile (event) {
      if (event.target.files.length) {
        this._files.push(event.target.files[0])
      }
    },
    removeFile (indexFile) {
      this._files.splice(indexFile, 1)
      $('#form-file-img + label').empty()
    }
  },
  components: {
    BaseTextarea,
    AbsoluteLoader
  }
}
</script>
<style lang="scss" scoped>
@import '@/theme/scss/_variables.scss';

.rma-addmessage{
  border: 1px solid;
  padding: 1rem;
}
.files-list-text{
  padding-top: 0.75rem;
  .filename-list{
    border: none;
    padding-left: 0
  }
}
</style>
