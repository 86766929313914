<template>
  <div>
    <b-table stacked="md" fixed responsive res :items="itemsByOrder" :fields="fields">
      <template #cell(item_image)="{ item }">
        <article class="rma-product">
          <img-product
            :imgPath="item.image_url"
            :alt="item.item_name"
            :styleImage="{ maxWidth: '60px' }"
          />
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
/* import moment from 'moment' */
import ImgProduct from '@/components/Product/Image'

export default {
  props: {
    orders: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    itemsByOrder () {
      let items = []
      let _item
      for (let i in this.orders) {
        for (let j in this.orders[i].items) {
          _item = { ...this.orders[i].items[j] }
          _item.order_number = this.orders[i].order_number
          items.push(_item)
        }
      }
      return items
    },
    fields () {
      return [{
        key: 'item_image',
        label: ''
      },
      {
        key: 'item_name'
      },
      {
        key: 'item_sku'
      },
      {
        key: 'order_number',
        label: this.$t('order.order')
      },
      {
        key: 'qty_requested'
      },
      {
        key: 'reason_name'
      },
      {
        key: 'condition_name'
      },
      {
        key: 'resolution_name'
      }]
    }
  },
  components: {
    ImgProduct
  }
}
</script>
<style lang="scss" scoped>
</style>
