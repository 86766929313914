<template>
  <div class="rma-messagelist_wrap">
    <h3>{{$t('rma.history')}}</h3>
    <p v-if="currentUser">{{ currentUser.firstname }} {{ currentUser.lastname }} </p>
      <ul class="rma-messagelist">
      <li v-for="(message, index) in messages" :key="index" :class="['msg-' + message.type]">
        <div class="rma-msg_title text-right">{{formatDate(message.date, $t('dateFormatLong'))}}</div>
        <div class="rma-msg_body">
          <div class="rma-msg_body_msg" v-html="message.message"/>
          <div class="rma-msg_body_attachment mt-2" v-if="message.attachments && message.attachments.length">
            <div
              v-for="(attachment, indexAttach) in message.attachments"
              :key="indexAttach"
              class="rma-msg_body_attachment__file mb-2">
              <a
                class="bcm-link bcm-link-primary"
                :href="attachment.url"
                >
                {{attachment.name}}
              </a>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  props: {
    messages: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    formatDate (date, format) {
      return moment(date).format(format)
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current
    })
  }
}
</script>
<style lang="scss" scoped>
@import '@/theme/scss/_variables.scss';

.rma-messagelist_wrap{
  border: 1px solid map-get($colors, gray-1);
  padding: 1rem
}
.rma-messagelist{
  list-style: none;
  margin-top: 2rem;
  margin-bottom: 0;
  padding-left: 0;
  max-height: 30rem;
  overflow: auto;
  li{
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 2rem;
    background-color: map-get($colors, dark-gray);
    border-radius: 4px;
    padding: 1.5rem 2rem;
    &.msg-system{
      margin-left: 10rem;
      background-color: map-get($colors, gray-1);
    }
    &.msg-customer{
      margin-right: 10rem;
    }
    &:last-child{
      margin-bottom: 0
    }
  }
}

</style>
